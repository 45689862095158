import { gql, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import InputField from '../../../common_lib_front/components/inputField/inputField';
import { InfoConfigValues } from '../../../common_lib_front/types/InfoConfigValues';
import style from './getPasses.module.css';
import { VehiclePass } from './types';
import { PassOptionErrorsType } from './validateVehicleData';

interface VehiclePassFormProps {
  vehiclePass: VehiclePass;
  passInfoId: string; // Add passInfoId as a prop to fetch vehicle data
  index: number;
  handleChange: (index: number, field: keyof VehiclePass, value: string) => void;
  handleCheckboxChange: (index: number) => void;
  smsAlert: boolean;
  promotionalAlert: boolean;
  duration: number | undefined;
  initialStartDate: string;
  initialEndDate: string;
  maxEndDate: string;
  errors?: PassOptionErrorsType;
  setSmsAlert: (value: boolean) => void;
  setPromotionalAlert: (value: boolean) => void;
}

// GraphQL query to get vehicle info
const GET_VEHICLE_INFO_CONFIG = gql`
  query GetVehicleInfoConfig($passInfoId: String!) {
    getVehicleInfoConfig(passInfoId: $passInfoId) {
      success
      error
      data {
        passInfoId
        make
        vehicleModel
        type
        color
        licensePlate
        fleetNumber
        licensePlateState
        primaryDriverName
        licensePrimaryDriver
        completeLater
      }
    }
  }
`;

const errorMessages = {
  type: 'Vehicle Type is required.',
  make: 'Vehicle Make is required.',
  color: 'Vehicle Color is required.',
  licensePlate: 'License Plate is required.',
  vehicleModel: 'Vehicle Model is required.',
  fleetNumber: 'Fleet Number is required.',
  licensePlateState: 'License Plate State is required.',
  primaryDriverName: 'Primary Driver Name is required.',
  licensePrimaryDriver: 'Primary Driver License is required.',
};

const VehiclePassForm: React.FC<VehiclePassFormProps> = ({
  vehiclePass,
  passInfoId,
  index,
  initialStartDate,
  initialEndDate,
  duration,
  errors,
  handleChange,
  handleCheckboxChange,
}) => {
  // Use the useQuery hook to fetch vehicle information based on passInfoId
  const { data, loading, error } = useQuery(GET_VEHICLE_INFO_CONFIG, {
    variables: { passInfoId },
    fetchPolicy: 'network-only',
  });
  const [config, setConfig] = useState<any>({}); // Store the configuration for required fields
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);
  const [maxEndDate, setMaxEndDate] = useState(initialEndDate);

  // Handle start date changes
  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newStartDate = e.target.value;
    setStartDate(newStartDate);
    handleChange(index, 'startDate', newStartDate);

    let start;
    if (newStartDate) {
      start = new Date(newStartDate);
    } else {
      start = new Date();
    }
    const calculatedMaxEndDate = new Date(start);
    calculatedMaxEndDate.setDate(start.getDate() + (duration || 0));

    const formattedMaxEndDate = calculatedMaxEndDate.toISOString().split('T')[0];
    if (formattedMaxEndDate <= initialEndDate) {
      setMaxEndDate(formattedMaxEndDate);
      setEndDate(formattedMaxEndDate);
      handleChange(index, 'endDate', formattedMaxEndDate);
    }
    // else {
    //   setMaxEndDate(initialEndDate);
    //   setEndDate(initialEndDate);
    //   handleChange(index, 'endDate', initialEndDate);
    // }
  };

  // Handle end date changes
  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedEndDate = new Date(e.target.value);
    const start = startDate ? new Date(startDate) : new Date();
    const maxAllowedEndDate = new Date(start);
    maxAllowedEndDate.setDate(start.getDate() + (duration || 0));

    const maxAllowedFormattedDate = maxAllowedEndDate.toISOString().split('T')[0];

    // Ensure the end date is within the allowed range
    if (selectedEndDate <= maxAllowedEndDate && e.target.value <= initialEndDate) {
      setEndDate(e.target.value);
      handleChange(index, 'endDate', e.target.value);
    } else {
      setEndDate(maxAllowedFormattedDate);
      handleChange(index, 'endDate', maxAllowedFormattedDate);
    }
  };

  // Update maxEndDate whenever startDate or duration changes
  useEffect(() => {
    let newMaxEndDate;
    if (startDate) {
      newMaxEndDate = new Date(startDate);
    } else {
      newMaxEndDate = new Date();
    }
    newMaxEndDate.setDate(newMaxEndDate.getDate() + (duration || 0));

    const formattedMaxEndDate = newMaxEndDate.toISOString().split('T')[0];
    if (formattedMaxEndDate <= initialEndDate) {
      setMaxEndDate(formattedMaxEndDate);
    } else {
      setMaxEndDate(initialEndDate);
    }

    // Adjust endDate if it's beyond the allowed range
    if (new Date(endDate) > newMaxEndDate) {
      setEndDate(formattedMaxEndDate);
      handleChange(index, 'endDate', formattedMaxEndDate);
    } else if (new Date(endDate) < new Date(initialStartDate)) {
      setEndDate(initialStartDate);
      handleChange(index, 'endDate', initialStartDate);
    }
  }, [startDate, duration, initialEndDate, initialStartDate, handleChange, index]);

  // Ensure startDate and endDate are set initially
  useEffect(() => {
    setStartDate(initialStartDate);
    setEndDate(initialEndDate);
    if (initialStartDate) {
      handleChange(index, 'startDate', initialStartDate);
    } else {
      handleChange(index, 'startDate', new Date().toISOString().split('T')[0]);
    }

    handleChange(index, 'endDate', initialEndDate);
  }, [initialStartDate, initialEndDate]);

  useEffect(() => {
    if (data?.getVehicleInfoConfig?.success) {
      const vehicleData = data.getVehicleInfoConfig.data;
      setConfig(vehicleData);
    }
  }, [data]);

  if (loading) return <p>Loading vehicle information...</p>;
  if (error) return <p>Error loading vehicle information: {error.message}</p>;
  const willComplteLater = config?.completeLater !== InfoConfigValues.disabled;
  console.log('vehiclePass=====>', vehiclePass);
  
  // Helper function to check if a field is "required"
  const isRequired = (field: string) => config[field] === 'required';
  return (
    <div className={style.vehiclePassForm}>
      <hr className={style.horizontalLine} />
      <div className={style.vehicleBox}>
        <div className={style.vehicleCheckboxContainer}>
          <h4 className={style.vehicleInfoTitle}>Pass Information</h4>
          <div className={style.vehicleLeftContainer}>
            {willComplteLater && (
              <>
                <input
                  type="checkbox"
                  checked={vehiclePass.dontKnowLicense}
                  onChange={() => handleCheckboxChange(index)}
                />
                <span className={style.vehiclecheckboxText}>
                  I dont know my license information right now.
                </span>
              </>
            )}
          </div>
        </div>
        <div className={style.vehicleFieldsContainer}>
          {isRequired('type') && (
            <div className={style.vehicleOptions}>
              <label className={style.vehicleOptionslabel}>Vehicle type</label>
              <select
                required
                className={style.vehicleOptionsInput}
                value={vehiclePass.type}
                disabled={vehiclePass.dontKnowLicense}
                onChange={e => handleChange(index, 'type', e.target.value)}
              >
                <option value="">Choose a vehicle type</option>
                <option value="Coupe (2 Door Car)">Coupe (2 Door Car)</option>
                <option value="Sedan (4 Door Car)">Sedan (4 Door Car)</option>
                <option value="Sports Car">Sports Car</option>
                <option value="Wagon/Station Wagon">Wagon/Station Wagon</option>
                <option value="Sports Utility Vehicle (SUV)">
                  Sports Utility Vehicle (SUV)
                </option>
                <option value="Minivan">Minivan</option>
                <option value="High Capacity Passenger Van">
                  High Capacity Passenger Van
                </option>
                <option value="Pickup Truck">Pickup Truck</option>
                <option value="Bus">Bus</option>
                <option value="Utility Truck">Utility Truck</option>
                <option value="Other Comercial Vehicle (Box Truck/Semi)">
                  Other Comercial Vehicle (Box Truck/Semi)
                </option>
                <option value="Trailer">Trailer</option>
                <option value="LSV">LSV</option>
              </select>
              {errors?.[passInfoId]?.[index]?.includes('type') ? (
                <span style={{ color: 'red' }}>{errorMessages.type}</span>
              ) : null}
            </div>
          )}
          {isRequired('make') && (
            <div className={style.vehicleOptions}>
              <label className={style.vehicleOptionslabel}>Vehicle make</label>
              <input
                required
                className={style.vehicleOptionsInput}
                type="text"
                value={vehiclePass.make}
                disabled={vehiclePass.dontKnowLicense}
                onChange={e => handleChange(index, 'make', e.target.value)}
                placeholder="Enter vehicle make"
              />
              {errors?.[passInfoId]?.[index]?.includes('make') ? (
                <span style={{ color: 'red' }}>{errorMessages.make}</span>
              ) : null}
            </div>
          )}
          {isRequired('color') && (
            <div className={style.vehicleOptions}>
              <label className={style.vehicleOptionslabel}>Vehicle color</label>
              <input
                required
                className={style.vehicleOptionsInput}
                type="text"
                value={vehiclePass.color}
                disabled={vehiclePass.dontKnowLicense}
                onChange={e => handleChange(index, 'color', e.target.value)}
                placeholder="Enter vehicle color"
              />
              {errors?.[passInfoId]?.[index]?.includes('color') ? (
                <span style={{ color: 'red' }}>{errorMessages.color}</span>
              ) : null}
            </div>
          )}
          {isRequired('licensePlate') && (
            <div className={style.vehicleOptions}>
              <label className={style.vehicleOptionslabel}>License plate</label>
              <input
                required
                className={style.vehicleOptionsInput}
                type="text"
                value={vehiclePass.licensePlate}
                disabled={vehiclePass.dontKnowLicense}
                onChange={e => handleChange(index, 'licensePlate', e.target.value)}
                placeholder="Enter your license plate number"
              />
              {errors?.[passInfoId]?.[index]?.includes('licensePlate') && !vehiclePass.licensePlate ? (
                <span style={{ color: 'red' }}>{errorMessages.licensePlate}</span>
              ) : null}
            </div>
          )}
          {isRequired('vehicleModel') && (
            <div className={style.vehicleOptions}>
              <label className={style.vehicleOptionslabel}>Vehicle Model</label>
              <input
                required
                className={style.vehicleOptionsInput}
                type="text"
                value={vehiclePass.vehicleModel}
                disabled={vehiclePass.dontKnowLicense}
                onChange={e => handleChange(index, 'vehicleModel', e.target.value)}
                placeholder="Enter your vehicle model"
              />
              {errors?.[passInfoId]?.[index]?.includes('vehicleModel') && !vehiclePass.vehicleModel ? (
                <span style={{ color: 'red' }}>{errorMessages.vehicleModel}</span>
              ) : null}
            </div>
          )}
          {isRequired('fleetNumber') && (
            <div className={style.vehicleOptions}>
              <label className={style.vehicleOptionslabel}>Fleet Number</label>
              <input
                required
                className={style.vehicleOptionsInput}
                type="text"
                value={vehiclePass.fleetNumber}
                disabled={vehiclePass.dontKnowLicense}
                onChange={e => handleChange(index, 'fleetNumber', e.target.value)}
                placeholder="Enter fleet number"
              />
              {errors?.[passInfoId]?.[index]?.includes('fleetNumber') ? (
                <span style={{ color: 'red' }}>{errorMessages.fleetNumber}</span>
              ) : null}
            </div>
          )}
          {isRequired('licensePlateState') && (
            <div className={style.vehicleOptions}>
              <label className={style.vehicleOptionslabel}>License Plate State</label>
              <input
                required
                className={style.vehicleOptionsInput}
                type="text"
                value={vehiclePass.licensePlateState}
                disabled={vehiclePass.dontKnowLicense}
                onChange={e => handleChange(index, 'licensePlateState', e.target.value)}
                placeholder="Enter your License Plate State"
              />
              {errors?.[passInfoId]?.[index]?.includes('licensePlateState') ? (
                <span style={{ color: 'red' }}>{errorMessages.licensePlateState}</span>
              ) : null}
            </div>
          )}
          {isRequired('primaryDriverName') && (
            <div className={style.vehicleOptions}>
              <label className={style.vehicleOptionslabel}>Primary Driver Name</label>
              <input
                required
                className={style.vehicleOptionsInput}
                type="text"
                value={vehiclePass.primaryDriverName}
                disabled={vehiclePass.dontKnowLicense}
                onChange={e => handleChange(index, 'primaryDriverName', e.target.value)}
                placeholder="Enter primary driver's name"
              />
              {errors?.[passInfoId]?.[index]?.includes('primaryDriverName') ? (
                <span style={{ color: 'red' }}>{errorMessages.primaryDriverName}</span>
              ) : null}
            </div>
          )}
          {isRequired('licensePrimaryDriver') && (
            <div className={style.vehicleOptions}>
              <label className={style.vehicleOptionslabel}>Primary Driver License</label>
              <input
                required
                className={style.vehicleOptionsInput}
                type="text"
                value={vehiclePass.licensePrimaryDriver}
                disabled={vehiclePass.dontKnowLicense}
                onChange={e =>
                  handleChange(index, 'licensePrimaryDriver', e.target.value)
                }
                placeholder="Enter primary driver's license"
              />
              {errors?.[passInfoId]?.[index]?.includes('licensePrimaryDriver') ? (
                <span style={{ color: 'red' }}>{errorMessages.licensePrimaryDriver}</span>
              ) : null}
            </div>
          )}
          {/* Date Fields for Start and End Date */}
          <div className={style.box__inputField}>
            <label htmlFor="check-out-inpt" className={style.label}>
              Valid starting on <span style={{ color: 'red' }}>*</span>
            </label>
            <div className={style.inputBox}>
              <InputField
                required
                smallSize
                htmlFor={`startDate_${passInfoId}_${index}-inpt`}
                inputType="date"
                inputValue={startDate}
                highlightOnFocus
                changeHandler={handleStartDateChange}
                inputPlaceholder="Choose the date"
                min={initialStartDate}
                max={initialEndDate}
              />
            </div>
          </div>
          <div className={style.box__inputField}>
            <label htmlFor="check-out-inpt" className={style.label}>
              Valid through <span style={{ color: 'red' }}>*</span>
            </label>
            <div className={style.inputBox}>
              <InputField
                required
                smallSize
                htmlFor={`endDate_${passInfoId}_${index}-inpt`}
                inputType="date"
                inputValue={endDate}
                highlightOnFocus
                changeHandler={handleEndDateChange}
                inputPlaceholder="Choose the date"
                min={startDate}
                max={initialEndDate}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default VehiclePassForm;
